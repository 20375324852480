import * as HighlightWords from "react-highlight-words"
import * as React from "react"
import { Box, Item } from "react-polymer-layout"
import { get } from "object-path"
import * as _ from "lodash"

export * from "./html"
export * from "./time"
export * from "./tmpCache"
export * from "./textflow"
export { default as csrfToken } from "./csrf"
import _toast from "./toast"
import { INFORMATION_COLUMN_WEIGHT } from "../misc/options"
import { MP_ACCOUNTS } from "../misc/crawler"
import { util as ResumeDocUtil } from "../components/ResumeDoc"
import { Icon } from 'antd'

export const avatar = ResumeDocUtil.avatar
export const countAge = ResumeDocUtil.countAge
export const iconURL = ResumeDocUtil.iconURL
export const patentTitle = ResumeDocUtil.patentTitle
export const salaryText = ResumeDocUtil.salaryText
export const thesisTitle = ResumeDocUtil.thesisTitle

export const toast = _toast

export function cloneJSON(json: object) {
    return JSON.parse(JSON.stringify(json))
}

export function imageURL(suffix: string) {
    return `/assets/images/${suffix}`
}

export function logo(style?: React.CSSProperties, type = "") {
    return <a className="clickable" href="/"><img src={imageURL(`logo${type}.png`)} style={style} /></a>
}

export function line(background, height = 1, style?) {
    return <div style={{ height, background, width: "100%", ...style }} />
}

export function blankTarget(text, link) {
    return <a href={link} target="_blank" style={{ color: "inherit" }}>{text}</a>
}

export function inputOnlyNumber(event) {
    let keyCode = event.which || event.keyCode
    if ([8, 9, 37, 39].indexOf(keyCode) >= 0) {
        // Backspace, Tab, Left, Right
        return
    } else if (keyCode >= 48 && keyCode <= 57) {
        // 0-9
        return
    } else if (keyCode >= 96 && keyCode <= 105) {
        // 0-9 on keypad
        return
    } else {
        event.preventDefault()
    }
}

export function inputOnlyTel(event) {
    let keyCode = event.which || event.keyCode
    if ([8, 9, 43, 45, 187, 189].indexOf(keyCode) >= 0) {
        // Backspace, Tab, +, -, +, -
        return
    } else if (keyCode >= 48 && keyCode <= 57) {
        // 0-9
        return
    } else if (keyCode >= 96 && keyCode <= 105) {
        // 0-9 on keypad
        return
    } else {
        event.preventDefault()
    }
}

export function inputOnlyAlphaNumber(event) {
    let keyCode = event.which || event.keyCode
    if ([8, 9].indexOf(keyCode) >= 0) return
    if (!/\w/.test(keyCode)) event.preventDefault()
}

export function intRange(min: number, max: number) {
    return function (value, resetValue) {
        if (!value) return
        let intValue = parseInt(value)
        if ("" + intValue !== "" + value || intValue < min || intValue > max || isNaN(intValue)) {
            resetValue()
        }
    }
}

export function range(min: number, max: number) {
    return function (value, resetValue) {
        if (!value) return
        let intValue = value
        if ("" + intValue !== "" + value || intValue < min || intValue > max || isNaN(intValue)) {
            resetValue()
        }
    }
}


// 通过onchange事件限制input输入框最多输入1位小数
export function rangeFix(min: number, max: number) {
    return function (value, resetValue) {
        if (!value) return
        let filterValue = `${value}`.replace('。', '.')
        let matchValue = filterValue.match(/[0-9]+(\.[0-9]?)?/)
        let intValue = matchValue ? matchValue[0] : filterValue
        if ("" + intValue !== "" + filterValue || parseFloat(intValue) < min || parseFloat(intValue) > max || isNaN(parseFloat(intValue))) {
            resetValue()
        }
    }
}

export function minWidth(width: string | number, style?: React.CSSProperties) {
    return <div style={{ minWidth: width, ...style }} />
}

export function minHeight(height: string | number, style?: React.CSSProperties) {
    return <div style={{ minHeight: height, ...style }} />
}

export function uploadErrorHandler(errorMessage?) {
    return (file, errsg, xhr) => {
        if (xhr) {
            try {
                //let resp = JSON.parse(file.xhr.responseText)
                toast.error("文件格式或大小(150*150)不符合要求!")
            } catch (e) {
                toast.error(xhr.responseText)
            }
        } else {
            toast.error(errorMessage || "文件格式或大小不符合要求")
        }
    }
}

export function formRow(content, hint?) {
    return (
        <Item flex relative>
            <Box>
                <Box center className="text-primary" style={{ width: "1rem" }}>
                    *
                </Box>
                <Item flex>
                    {content}
                </Item>
            </Box>
            {hint}
        </Item>
    )
}

export function highlightWords(text: string, query: string) {
    if (!query) return text
    return <HighlightWords highlightClassName="text-color-v2"
        textToHighlight={text} searchWords={query.split(/\s+/).filter(t => !!t)} />
}

export function textPrimary(text, style?) {
    return <span className="text-primary" style={style}>{text}</span>
}

export function textGray(text, style?) {
    return <span className="text-gray" style={style}>{text}</span>
}

export function textMinor(text, style?) {
    return <span className="text-minor" style={style}>{text}</span>
}

export function textError(text, style?) {
    return <span className="text-error" style={style}>{text}</span>
}

export function passwordError(that) {
    return passwordError2(that.state)
}

export function passwordError1(password) {
    if (!password || password.length === 0) {
        return ""
    } else if (password.length < 6) {
        return "密码长度过短"
    } else if (password.length > 16) {
        return "密码长度过长"
    } else if (!/[0-9]/.test(password) || !/[A-Za-z]/.test(password)) {
        return "必须包含字母与数字"
    } else {
        return null
    }
}

export function passwordError2(passwords) {
    const { password, password$ } = passwords
    if (!password || !password$ || password.length === 0 || password$.length === 0) {
        return ""
    } else if (password !== password$) {
        return "两次输入的密码不一致"
    } else {
        return passwordError1(password)
    }
}



export function isWebURL(url) {
    // 正则表达式由/^(http|https):\/\/[a-z0-9./-]+$/i修改为/^(http|https):\/\//i
    // 因为当出现%&_等特殊符号，不会被匹配，因此改宽容点
    const re = /^(http|https):\/\//i
    // true为通过
    return re.test(url)

}

export function validateWebId(id) {
    // 请勿输入http、空格以及除了-，_和.以外的特殊符号，请勿以.结尾
    const enRe = /http|\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\=|\+|\{|\[|\]|\}|\||\\|\;|\:|\'|\"|\,|\<|\>|\/|\?|\s|\.$/i
    const zhRe = /\·|\！|\￥|\…|\（|\）|\【|\】|\、|\；|\：|\‘|\’|\“|\”|\，|\《|\》|\。|\？/
    const res = enRe.test(id) || zhRe.test(id)
    // true为不通过
    return res
}

// 校验微信公众号id
export function validateId(id) {
    // 请勿输入http、空格以及除了-和_以外的特殊符号
    const enRe = /http|\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\=|\+|\{|\[|\]|\}|\||\\|\;|\:|\'|\"|\,|\<|\.|\>|\/|\?|\s/i
    const zhRe = /\·|\！|\￥|\…|\（|\）|\【|\】|\、|\；|\：|\‘|\’|\“|\”|\，|\《|\》|\。|\？/
    const res = enRe.test(id) || zhRe.test(id)
    // true为不通过
    return res
}

export function containsEmail(email) {
    const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
    return re.test(email)
}

export function isEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

// polyfill es5 ts error: "Property 'replaceAll' does not exist on type 'string'"
export function replaceAll(str: string, regex: RegExp, replaceWith: string ) {
    if(!str) {
        str = "未知"
    }
    return str.replace(regex, replaceWith)
}

export function splitTags(text: string) {
    if (!text) return []
    return text.split(/[,;，；、]/g).map(s => s.trim()).filter(s => !!s)
}

export function unique(list: any[]) {
    let result = {}
    list.forEach(k => result[k] = true)
    return Object.keys(result)
}

export function uniqueById(list: any[]) {
    let idMap = {}
    let result: any[] = []
    list.forEach(r => {
        if (r && r.id && !idMap.hasOwnProperty(r.id)) {
            result.push(r)
            idMap[r.id] = true
        }
    })
    return result
}

export function getViewID() {
    return location.pathname.split("/").filter(s => s).reverse()[0]
}

export function getIconName(filename) {
    if (/\.(jpg|jpeg|png)$/.test(filename.toLowerCase())) return "image"
    if (/\.pdf$/.test(filename.toLowerCase())) return "pdf"
    if (/\.(doc|docx)$/.test(filename.toLowerCase())) return "word"
    if (/\.(xls|xlsx)$/.test(filename.toLowerCase())) return "excel"
}

export function hint(text: string, className?: string, style?: any) {
    return (
        <div style={{ fontSize: ".7rem", marginTop: ".625rem", lineHeight: 1, ...style }}>
            <span className={className}>
                {text}
            </span>
        </div>
    )
}

export function lineHeight1(text: any, fontSize?: number, style?) {
    return <div style={{ lineHeight: 1, fontSize, ...style }}>{text}</div>
}

export function lineHeight2(text: any, fontSize: number, className?: string) {
    return (
        <div className={className} style={{ fontSize: fontSize + "rem", lineHeight: 2, margin: "-0.5em 0" }}>
            {text}
        </div>
    )
}

// 根据长度截取字符串后加省略号
export function ellipsis(text, length: number) {
    if (typeof text !== "string") return text
    let result = "", count = 0
    for (let i = 0; i < text.length; i++) {
        if (count >= length) return result.replace(/\w+$/, "") + "..."
        count += text.charCodeAt(i) < 256 ? 0.5 : 1
        result += text.charAt(i)
    }
    return result
}

export function switchTab(that, tab, callback?) {
    return () => that.setState({ tab }, callback)
}

export function researchFields(job) {
    return job.employerType === "COLLEGE" ? `研究方向：${(job.researchFields || []).join("；")}` :
        `所属行业：${(job.industries || []).join("；")}`
}

export function jobRequirements(job, canBeEmpty = false) {
    if (job.requirements) {
        return job.requirements.join("、") || (canBeEmpty ? "" : "无")
    } else {
        return job.requirement || (canBeEmpty ? "" : "无")
    }
}

export function jobNameWithTalentPlans(name, talentPlans) {
    if (talentPlans) {
        talentPlans = talentPlans.filter(p => p && p !== "无")
        if (talentPlans.length) {
            return `${name}（${talentPlans.join("、")}）`
        } else {
            return name
        }
    } else {
        return name
    }
}

export function fullEmployerName(name, department) {
    if (!department) {
        return name
    } else if (department === "无") {
        return name
    } else {
        return name + department
    }
}

export function jobTitle(job) {
    return fullEmployerName(job.employerName, job.department) + job.name
}

export function imageCenter(src, extraStyle?) {
    let style: any = {
        backgroundImage: `url(${src})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        ...extraStyle
    }
    return <div style={style} />
}

export function imageCover(src, width: string | number, extraStyle?, className?) {
    let style: any = {
        backgroundImage: `url(${src})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        width,
        minWidth: width,
        height: width,
        ...extraStyle
    }
    return <div className={className} style={style} />
}

export function imageMask(src, width: string | number, height: string | number, onClick?, extraStyle?, noMask = false, extraMaskStyle?) {
    let style: any = {
        backgroundImage: `url(${src})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: 2,
        minWidth: width,
        width,
        height,
        ...extraStyle,
    }
    return (
        <div className="hover-image-zoom" onClick={onClick}
            style={{
                width, height, minWidth: width, overflow: "hidden", position: "relative",
                cursor: onClick ? "pointer" : "default",
            }}>
            <div className="zoom" style={style} />
            {noMask ? null : <Item fit className="mask" style={{ background: "rgba(25,25,25,0.1)", ...extraMaskStyle }} />}
        </div>
    )
}

export function LazyImageMask(src, defaultSrc, width: string | number, height: string | number, onClick?, extraStyle?, noMask = false, extraMaskStyle?) {
    let style: any = {
        backgroundImage: `url(${defaultSrc})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: 2,
        minWidth: width,
        width,
        height,
        ...extraStyle,
    }
    return (
        <div className="hover-image-zoom" onClick={onClick}
            style={{
                width, height, minWidth: width, overflow: "hidden", position: "relative",
                cursor: onClick ? "pointer" : "default",
            }}>
            <div className="zoom" style={style} data-src={src} />
            {noMask ? null : <Item fit className="mask" style={{ background: "rgba(25,25,25,0.1)", ...extraMaskStyle }} />}
        </div>
    )
}

export function imageContain(src, width: string | number, extraStyle?, className?, onClick?) {
    let style: any = {
        backgroundImage: `url(${src})`,
        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width,
        minWidth: width,
        height: width,
        ...extraStyle
    }
    return <div className={className} style={style} onClick={onClick} />
}

export function imageNewContain(source, width: string | number, extraStyle?, className?, onClick?) {
    if(!source) return null 
    const defaultLogo = "sources/default.svg"
    let style: any = {
        backgroundImage: `url(${ source.logo ? source.logo : defaultLogo})`,
        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width,
        minWidth: width,
        height: width,
        ...extraStyle
    }
    return <div className={className} style={style} onClick={onClick} />
}

export function empty(text?, style?, className?: string) {
    return (
        <div className={`line-height text-center text-minor ${className || ""}`} style={{ padding: "6rem 0 6rem", ...style }}>
            <img src={iconURL("empty.png")} style={{ height: "5.5rem" }} />
            {minHeight("2.5rem")}
            <div>
                {text || "这里暂时还没有内容"}
            </div>
        </div>
    )
}

export function resumeCompleted(item) {
    if (!item) return 0
    let p = 40
    if (item.profileZh) {
        let { educations, experiences, intentions, projects, patentList, thesisList, awards, recommendations, supplements } = item.profileZh
        if (item.profileZh.avatar) p += 3
        let { name, birth, degree, researchField, major, occupation, organization, department, city, phone, email } = item.profileZh
        p += (name && birth && degree && researchField && major && occupation && organization && department && city && phone && email) ? 9 : 0
        if (educations && educations.length) p += 9
        if (experiences && experiences.length) p += 9
        if (projects && projects.length) p += 9
        if (patentList && patentList.length) p += 3
        if (thesisList && thesisList.length) p += 3
        if (awards && awards.length) p += 3
        if (recommendations && recommendations.length) p += 3
        if (supplements && supplements.length) p += 3
        if (intentions && intentions.length) p += 6
    }
    return p
}

export function mergeArrayById(array1: any[], array2: any[]) {
    let indexTable = {}
    let result = [] as any[]
    array1.concat(array2).forEach(x => {
        if (indexTable[x.id] === undefined) {
            result.push(x)
            indexTable[x.id] = result.length - 1
        } else {
            result[indexTable[x.id]] = x
        }
    })
    return result
}

export function getSourceName(source) {
    if (!source) return "领研网"
    if (source.sourceType === "WEIXIN" && (source.sourceName || source.sourceId)) return source.sourceName || MP_ACCOUNTS[source.sourceId] || source.sourceId
    if (source.sourceType === "OTHER" && (source.sourceName || source.sourceId)) return source.sourceName || MP_ACCOUNTS[source.sourceId] || source.sourceId
    if (source.sourceType === "WEBSITE" && (source.sourceName || source.sourceId)) return source.sourceName || source.sourceId
    return "领研网"
}

export function getNewSourceName(source) {
    if (!source) return "领研网"
    if (source.sourceType === "WEIXIN" && (source.sourceName || source.sourceId)) return source.sourceName || MP_ACCOUNTS[source.sourceId] || source.sourceId
    if (source.sourceType === "OTHER" && (source.sourceName || source.sourceId)) return source.sourceName || MP_ACCOUNTS[source.sourceId] || source.sourceId
    if (source.sourceType === "WEBSITE" && (source.sourceName || source.sourceId)) return source.sourceName || source.sourceId
    return "领研网"
}

/** @deprecated */
export function getPublisher(source) {
    if (!source) return "管理员"
    if (source && source.sourceType === "WEIXIN") {
        if (source.sourceId) {
            return source.sourceName || MP_ACCOUNTS[source.sourceId] || "管理员"
        } else {
            return source.sourceName || "管理员"
        }
    } else {
        return source.sourceName || "管理员"
    }
}

export function getSourceLogo(source, style?, defaultLogo = "sources/default.svg", DEFAULT_LOGO = "sources/DEFAULT.jpeg") {
    let sourceType = get(source, "sourceType")
    let sourceId = get(source, "sourceId")
    let onError = e => handleSourceLogoError(e, defaultLogo)
    if (sourceType && (source.sourceName || source.sourceId)) {
        if (sourceId) {
            return <img src={iconURL("sources/WEIXIN/" + sourceId + ".jpeg")} style={style} onError={onError} />
        } else {
            return <img src={iconURL(defaultLogo)} style={style} />
        }
    }
    // else if (sourceType === "WEBSITE" && (source.sourceName || source.sourceId)) {
    //     return <img src={iconURL(defaultLogo)} style={style} />
    // } 
    else {
        return <img src={iconURL(DEFAULT_LOGO)} style={style} />
    }
}

export function getSourceLogoByQiNiu(source, style?, defaultLogo = "sources/default.svg", DEFAULT_LOGO = "sources/DEFAULT.jpeg") {
    let sourceType = get(source, "sourceType")
    let sourceId = get(source, "sourceId")
    let onError = e => handleSourceLogoError(e, defaultLogo)
    if (sourceType && (source.sourceName || source.sourceId)) {
        if (sourceId) {
            return <img src={`https://cdn.linkresearcher.com/${sourceId}.jpeg`} style={style} onError={onError} />
        } else {
            return <img src={iconURL(defaultLogo)} style={style} />
        }
    }
    // else if (sourceType === "WEBSITE" && (source.sourceName || source.sourceId)) {
    //     return <img src={iconURL(defaultLogo)} style={style} />
    // } 
    else {
        return <img src={iconURL(DEFAULT_LOGO)} style={style} />
    }
}

export function getNewSourceLogoByQiNiu(source, style?, defaultLogo = "sources/default.svg", DEFAULT_LOGO = "sources/DEFAULT.jpeg") {
    if(!source) return null
    let {sourceType, sourceId, sourceName, logo} = source
    let onError = e => handleSourceLogoError(e, defaultLogo)
    if (sourceType && (sourceName || sourceId)) {
        if (sourceId) {
            return <img src={logo} style={style} onError={onError} />
        } else {
            return <img src={iconURL(defaultLogo)} style={style} />
        }
    } else {
        return <img src={iconURL(DEFAULT_LOGO)} style={style} /> 
    }
}

export function handleSourceLogoError(e, logo = "sources/default.svg") {
    const defaultLogo = iconURL(logo)
    if (e.target.src !== defaultLogo) e.target.src = defaultLogo
}

export function getInformationColumnByWeight(columns: string[]) {
    return cloneJSON(columns || []).sort((a, b) => {
        return (INFORMATION_COLUMN_WEIGHT[b] || 0) - (INFORMATION_COLUMN_WEIGHT[a] || 0)
    })[0] || "观察"
}

export function getEventTextByPathname() {
    switch (location.pathname.split("/")[1]) {
        case "meetings": return "会议"
        case "lectures": return "讲座"
        case "trainings": return "培训"
        case "lives": return "直播"
        case "studytours": return "游学"
        case "contests": return "比赛"
        default: return "活动"
    }
}

export function getEventInfoByPathname() {
    switch (location.pathname.split("/")[1]) {
        case "meetings": return ["会议", "/meetings"]
        case "lives": return ["直播", "/lives"]
        case "contests": return ["比赛", "/contests"]
        case "trainings": return ["培训", "/trainings"]
        case "studytours": return ["游学", "/studytours"]
        case "lectures": return ["讲座", "/lectures"]
        default: return ["活动", "/events"]
    }
}

export function wechatQRCode(source, style?: React.CSSProperties, onlineTime?) {
    if (!source) {
        return null
    } else if (onlineTime < 1543420800000) {
        // new Date(2018, 10, 29)
        return null
    } else if (source.sourceType === "WEIXIN") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请关注“{source.sourceName}”（{source.sourceId}）
                    <br />
                    <Box centerJustified>
                        <Item relative style={{ width: innerWidth < 600 ? "48%" : "33%" }}>
                            <img
                                src={`https://open.weixin.qq.com/qr/code?username=${source.sourceId}`}
                                style={{ width: "100%", verticalAlign: "top", border: "8px solid white" }}
                            />
                        </Item>
                    </Box>
                </div>
            )
        }
    } else if (source.sourceType === "WEBSITE") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请访问“{source.sourceName}”官方网站：
                    <br />
                    <a target="_blank" href={source.url}>{source.url}</a>
                </div>
            )
        }
    } else if (source.sourceType === "OTHER") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请关注“{source.sourceName}”
                    <br />
                </div>
            )
        }
    } else {
        return null
    }
}

export function wechatNewQRCode(source, style?: React.CSSProperties, onlineTime?) {
    if(!source) {
        return null
    } else if (onlineTime < 1543420800000) {
        // new Date(2018, 10, 29)
        return null
    } else if (source.sourceType === "WEIXIN") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请关注“{source.sourceName}”（{source.sourceId}）
                    <br />
                    <Box centerJustified>
                        <Item relative style={{ width: innerWidth < 600 ? "48%" : "33%" }}>
                            <img
                                src={source.qrCode}
                                style={{ width: "100%", verticalAlign: "top", border: "8px solid white" }}
                            />
                        </Item>
                    </Box>
                </div>
            )
        }
    } else if (source.sourceType === "WEBSITE") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请访问“{source.sourceName}”官方网站：
                    <br />
                    <a target="_blank" href={source.url}>{source.url}</a>
                </div>
            )
        }
    } else if (source.sourceType === "OTHER") {
        if (source.sourceId) {
            return (
                <div style={{ textAlign: "center", fontSize: "0.95rem", lineHeight: 1.8, color: "#666", ...style }}>
                    <br />
                    更多精彩内容，请关注“{source.sourceName}”
                    <br />
                </div>
            )
        }
    } else {
        return null
    }
}

export function num2Zh(num: number, limit = 4, _limit = 8) {
    let _num = `${num}`
    let str: number | string

    if (_num.length > limit && _num.length <= _limit) {
        str = `${Math.floor(num / 10000)}万+`
    } else if (_num.length > _limit) {
        str = `${Math.floor(num / 100000000)}亿+`
    } else {
        str = num
    }

    return str
}

export function mergePaper(paper, res) {
    paper.title = res.thesisTitle || paper.title
    paper.authors = res.authors && res.authors.length ? res.authors : paper.authors
    paper.journal = res.periodical || paper.journal
    paper.publishDate = res.publishDate || paper.publishDate
    paper.summary = res.summary || paper.summary
    paper.tags = res.tags && res.tags.length ? res.tags : paper.tags
}

export function gradientColor(startColor: string, endColor: string, step: number) {
    const formatColor = (color: string) => {
        let regRGB = /^(rgb|RGB)/
        let regHEX = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
        let colorArr: number[] = []

        if (regRGB.test(color)) {
            colorArr = _.map(color.replace(/(?:(|)|rgb|RGB)*/g, "").split(","), i => parseInt(i))
        } else if (regHEX.test(color)) {
            let HEX = color.replace(/#/, "").split("")
            if (HEX.length === 3) {
                colorArr[0] = parseInt(HEX[0], 16) * parseInt(HEX[0], 16)
                colorArr[1] = parseInt(HEX[1], 16) * parseInt(HEX[1], 16)
                colorArr[2] = parseInt(HEX[2], 16) * parseInt(HEX[2], 16)
            } else if (HEX.length === 6) {
                colorArr[0] = parseInt(HEX[0], 16) * parseInt(HEX[1], 16)
                colorArr[1] = parseInt(HEX[2], 16) * parseInt(HEX[3], 16)
                colorArr[2] = parseInt(HEX[4], 16) * parseInt(HEX[5], 16)
            } else {
                return false
            }
        } else {
            return false
        }

        return colorArr
    }
    let colorArr: string[] = []
    let startRGB = formatColor(startColor)
    let startR = startRGB[0]
    let startG = startRGB[1]
    let startB = startRGB[2]
    let endRGB = formatColor(endColor)
    let endR = endRGB[0]
    let endG = endRGB[1]
    let endB = endRGB[2]
    let sR = (endR - startR) / step
    let sG = (endG - startG) / step
    let sB = (endB - startB) / step
    for (let i = 0; i < step; i++) {
        let color = `rgb(${parseInt(`${sR * i + startR}`)},${parseInt(`${sG * i + startG}`)},${parseInt(`${sB * i + startB}`)})`
        colorArr.push(color)
    }

    return colorArr
}

// 首页的科研圈日报组件
export function horizontalWrapper (content, className, offset = 200) {

    const moveLeft = () => {
        document.querySelector(className).scrollLeft -= offset
    }
    const moveRight = () => {
        document.querySelector(className).scrollLeft += offset
    }

    return (
        <div className="horizontal-wrapper">
            <div className="move-left move" onClick={() => moveLeft()}><Icon type="left" /></div>
            <div className="content">
                {content}
            </div>
            <div className="move-right move" onClick={() => moveRight()}><Icon type="right" /></div>
        </div>
    )
}

// 网站默认作品集声明
export const getPortfolioStatement = () => "未经许可请勿转载，获得授权请联系 contact@linkresearcher.com 或致电 010-85325984。"

// 网站默认作品集来源声明
export const getPortfolioSourceStatement = sourceText => `本文转载自“${ sourceText }”。凡本网站转载、引用的文章、图片、音频、视频文件等资料的版权归版权所有人所有，如因此产生相关后果，将由版权所有人、原始发布者和内容提供者承担，如有侵权请尽快联系删除。`

export const copyRichContent = (element) => {
    // 选中需要复制的元素内容
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  
    // 复制选中内容到剪贴板
    let successful = false;
    try {
      successful = document.execCommand("copy");
    } catch (err) {
      console.error("复制失败", err);
      toast.error("复制失败");
    }
    // 清除选中内容
    selection.removeAllRanges();
    return successful;
  };
  
import * as React from "react"
import * as ReactDOM from "react-dom"
import Calendar from "react-calendar"
import Dropzone from "react-dropzone-component"
import { Box, Item } from "react-polymer-layout"
import { get as deepGet, set as deepSet } from "object-path"
import { Switch } from 'antd';
import * as __ from "lodash"

import Button from "semantic-ui-react/dist/commonjs/elements/Button"
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox"
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown"
import Form from "semantic-ui-react/dist/commonjs/collections/Form"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon"
import Input from "semantic-ui-react/dist/commonjs/elements/Input"
import Label from "semantic-ui-react/dist/commonjs/elements/Label"
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup"
import Search from "semantic-ui-react/dist/commonjs/modules/Search"
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio"
import Select from "semantic-ui-react/dist/commonjs/addons/Select"
import Table from "semantic-ui-react/dist/commonjs/collections/Table"
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea"
import IconPicker from "./IconPicker"

import * as util from "../util"
import UploadFile from "./UploadFile"
import UploadFile2 from "./UploadFile2";

export {
    Box,
    Item,
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Label,
    Popup,
    Radio,
    Select,
    Table,
    TextArea,
    Search
}

interface InputCalendarProps {
    value: string
    onChange: any
    disabled?: boolean
    max?: string
    min?: string
    className?: string
}

interface InputCalendarState {
    focus: boolean
}

class InputCalendar extends React.Component<InputCalendarProps, InputCalendarState> {
    constructor(props: InputCalendarProps) {
        super(props)
        this.state = { focus: false }
    }

    componentDidMount() {
        window.addEventListener("click", e => {
            if (!this.refs || !this.refs.calendar || !e.target) return
            if (!(this.refs.calendar as any).contains(e.target)) {
                this.setState({ focus: false })
            }
        })
    }

    render() {
        return (
            <span ref="calendar" style={{ position: "relative" }}>
                <Input fluid value={(this.props.value || "").replace(/-/g, "/")} disabled={this.props.disabled}
                    onFocus={() => this.setState({ focus: true })} />

                {this.state.focus ? (
                    <span style={{ position: "absolute", bottom: "2.6em", zIndex: 1 }} className={this.props.className}>
                        <Calendar value={new Date(this.props.value)}
                            onChange={(d: Date) => {
                                let value = util.dateString(d.getTime())
                                this.props.onChange(value)
                                this.setState({ focus: false })
                            }} />
                    </span>
                ) : null}
            </span>
        )
    }
}

class InputMonth extends React.Component<InputCalendarProps, InputCalendarState> {
    constructor(props: InputCalendarProps) {
        super(props)
        this.state = { focus: false }
    }

    render() {
        let year = this.props.value.split("-")[0]
        let month = this.props.value.split("-")[1]

        let maxYear = 2099
        if (typeof this.props.max === "string") {
            maxYear = parseInt(this.props.max.split("-")[0])
        }

        let years: any[] = []
        for (let i = maxYear; i >= 1900; i--) {
            years.push({
                text: i + "年",
                value: i + "",
            })
        }
        let months: any[] = []
        for (let i = 1; i <= 12; i++) {
            months.push({
                text: i + "月",
                value: i < 10 ? "0" + i : i + "",
            })
        }

        return (
            <Box>
                <Select fluid placeholder="年" value={year} options={years} disabled={this.props.disabled}
                    onChange={(event, data) => {
                        let value = data.value + "-" + month
                        if (this.props.max && value > this.props.max) {
                            util.toast.error("无效的日期范围")
                        } else if (this.props.min && value < this.props.min) {
                            util.toast.error("无效的日期范围")
                        } else {
                            this.props.onChange(data.value + "-" + month)
                        }
                    }} />
                {util.minWidth("1.25rem")}
                <Select fluid placeholder="月" value={month} options={months} disabled={this.props.disabled}
                    onChange={(event, data) => {
                        let value = year + "-" + data.value
                        if (this.props.max && value > this.props.max) {
                            util.toast.error("无效的日期范围")
                        } else if (this.props.min && value < this.props.min) {
                            util.toast.error("无效的日期范围")
                        } else {
                            this.props.onChange(year + "-" + data.value)
                        }
                    }} />
            </Box>
        )
    }
}

interface InputTagLogoProps {
    value: string
    onChange: any
}

interface InputTagLogoState {
    options: any[]
}

class InputTagLogo extends React.Component<InputTagLogoProps, InputTagLogoState> {
    constructor(props: InputTagLogoProps) {
        super(props)
        this.state = {
            options: [
                {text: <IconPicker pickId="1"/>, value: "1"}, 
                {text: <IconPicker pickId="3"/>, value: "3"}, 
                {text: <IconPicker pickId="4"/>, value: "4"},
                {text: <IconPicker pickId="5"/>, value: "5"},
                {text: <IconPicker pickId="6"/>, value: "6"},
                {text: <IconPicker pickId="7"/>, value: "7"},
                {text: <IconPicker pickId="8"/>, value: "8"},
                {text: <IconPicker pickId="9"/>, value: "9"},
                {text: <IconPicker pickId="10"/>, value: "10"},
                {text: <IconPicker pickId="11"/>, value: "11"},
                {text: <IconPicker pickId="12"/>, value: "12"},
                {text: <IconPicker pickId="13"/>, value: "13"},
                {text: <IconPicker pickId="14"/>, value: "14"},
                {text: <IconPicker pickId="15"/>, value: "15"},
                {text: <IconPicker pickId="16"/>, value: "16"},
                {text: <IconPicker pickId="17"/>, value: "17"},
            ]
        }
    }

    render() {
        return (
             <Box>
                <Select fluid value={this.props.value || "1"} options={this.state.options} disabled={false}
                    onChange={(event, data) => {
                        this.props.onChange(data.value)
                    }}
                />
            </Box>
        )
    }
}

interface InputTagsProps {
    value: any
    onChange: any
    placeholder?: string
    noResultsMessage?: string
    maxlength?: number
    disabled?: boolean
    options?: any[]
    splitable?: boolean
    upward?: boolean
    couldNotEdit?: boolean
}

interface InputTagsState {
    search: string
    options: any[]
}

class InputTags extends React.Component<InputTagsProps, InputTagsState> {
    constructor(props: InputTagsProps) {
        super(props)
        this.state = {
            search: "",
            options: [],
        }
    }

    valueToOption(value: string) {
        return { key: value, value, text: value }
    }

    clearSearch() {
        let dropdown = ReactDOM.findDOMNode((this.refs as any).dropdown)
        setTimeout(() => (dropdown.querySelector("input.search") as any).value = "", 100)
    }

    mergeOptions(list1, list2) {
        let result: any[] = []
        let values = {}
        Array(...(list1 || []), ...(list2 || [])).forEach(x => {
            if (values[x.value]) return
            values[x.value] = true
            result.push(x)
        })
        // 过滤非空选项 并 排序
        result = result.filter((item) => !!item.value).sort((a, b) => a.value.length - b.value.length)
        return result
    }

    render() {
        let value = this.props.value || []
        if (Array.isArray(value)) {
        }else{
            value = value.split(';')
        }
        let options = this.mergeOptions(
            (value || []).map(v => this.valueToOption(v)),
            this.mergeOptions(this.props.options, this.state.options)
        )
        let dropdown = (
            <Dropdown ref="dropdown" fluid multiple search selection icon={null}
                disabled={this.props.disabled} placeholder={this.props.placeholder}
                noResultsMessage={this.props.noResultsMessage || "按 Enter 键确认"}
                value={this.props.value || []} 
                options={options}
                allowAdditions={!this.props.couldNotEdit} additionLabel=""
                upward={this.props.upward}
                onFocus={(e) => {
                    const input = e.target as HTMLInputElement
                    if (input) input.maxLength = 180
                }}
                onAddItem={(e, { value }) => {
                    value = (value as string).trim()
                    this.setState({
                        options: this.mergeOptions(this.state.options, [this.valueToOption(value as any)]),
                    })
                }}
                onSearchChange={(e, value) => this.setState({ search: value })}
                onChange={(e, { value }) => {
                    this.props.onChange(this.props.maxlength ? (value as string[]).slice(0, this.props.maxlength) : value)
                    this.clearSearch()
                }} />
        )
        return this.props.splitable ? (
            <Box center>
                {dropdown}
                {util.minWidth("1.25rem")}
                <div className="text-primary clickable nowrap" style={{ fontSize: ".9rem" }}
                    onClick={() => {
                        let newValue = this.props.value
                        let tags = util.splitTags(this.state.search)
                        tags.forEach(tag => newValue = newValue.indexOf(tag) < 0 ? [...newValue, tag] : newValue)
                        this.setState({
                            options: this.mergeOptions(this.state.options, newValue.map(v => this.valueToOption(v)))
                        })
                        this.props.onChange(newValue)
                        this.clearSearch()
                    }}>
                    自动拆分
                </div>
            </Box>
        ) : dropdown
    }
}

export const elements = {
    formSelect(that, key, props) {
        props.options = props.options.map(opt => {
            return {
                className: opt.className,
                value: opt.value,
                text: opt.text || opt.value,
            }
        })
        return (
            <Select fluid {...props} value={deepGet(that.state, key)}
                onChange={(event, data) => {
                    deepSet(that.state, key, data.value)
                    that.setState({})
                    if (props._onChange) props._onChange(event, data)
                }} />
        )
    },

    formSelect2(that, key, props) {
        return (
            <Box>
                <div style={{ width: "1.25rem" }} />
                <Item flex>
                    {this.formSelect(that, key, props)}
                </Item>
            </Box>
        )
    },

    formInput(that, key, props) {
        // console.log(that, key, "key....")
        switch (props && props.type) {
            case "calendar":
                if (!deepGet(that.state, key)) {
                    let defaultValue = util.dateString(Date.now())
                    deepSet(that.state, key, defaultValue)
                    setTimeout(() => that.setState({}), 0)
                }
                return (
                    <InputCalendar value={deepGet(that.state, key)} {...props}
                        onChange={value => {
                            deepSet(that.state, key, value)
                            that.setState({}, props && props._onChange)
                        }} />
                )
            case "month":
                if (!deepGet(that.state, key)) {
                    let defaultValue = util.dateString(Date.now()).substr(0, 7)
                    deepSet(that.state, key, defaultValue)
                    setTimeout(() => that.setState({}), 0)
                }
                return (
                    <InputMonth value={deepGet(that.state, key)} disabled={props.disabled}
                        max={props.max} min={props.min} onChange={value => {
                            deepSet(that.state, key, value)
                            that.setState({})
                        }} />
                )
            case "tagLogo": 
                return (
                    <InputTagLogo value={deepGet(that.state, key)}
                        onChange={value => {
                            deepSet(that.state, key, value)
                            that.setState({})
                        }}
                    />
                )
            case "tags":
                deepSet(that.state, key, deepGet(that.state, key) || [])
                return (
                    <InputTags value={deepGet(that.state, key)} {...props}
                        maxlength={props.maxlength} placeholder={props.placeholder}
                        onChange={value => {
                            deepSet(that.state, key, value)
                            that.setState({})
                        }} />
                )
            case "switch" :
                return <div>
                            {/* <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={deepGet(that.state, key)}
                                onChange={(checked, event) => {
                                    deepSet(that.state, key, checked)
                                    that.setState({})
                                }}/> */}
                            <Switch checkedChildren={props.checkedChildren} unCheckedChildren={props.unCheckedChildren} defaultChecked={deepGet(that.state, key)}
                                onChange={(checked, event) => {
                                    deepSet(that.state, key, checked)
                                    that.setState({})
                                }}/>
                        </div>
            case "select":
                return this.formSelect(that, key, props)
            case "textarea":
                return (
                    <Form>
                        <TextArea {...props} value={deepGet(that.state, key)}
                            onChange={(e, { value }) => {
                                deepSet(that.state, key, value)
                                that.setState({})
                            }} />

                        <div className="text-minor"
                            style={{
                                display: props.maxlength ? "block" : "none",
                                textAlign: "right",
                                lineHeight: 2,
                                fontSize: ".75rem",
                            }}>
                            已输入 {(deepGet(that.state, key, "") || "").length}/{props.maxlength}
                        </div>
                    </Form>
                )
            case "upload":
                return (
                    <UploadFile title={props.title} url={deepGet(that.state, key)} disabled={props.disabled}
                        style={{
                            height: "10rem",
                            background: "rgb(250, 250, 250)",
                            border: "1px solid rgba(34,36,38,.15)",
                            borderRadius: ".28571429rem",
                            ...props.style,
                        }}
                        imgType={props.imgType}
                        sourceid={props.sourceid}
                        newUrl={props.newUrl}
                        onChange={resp => {
                            deepSet(that.state, key, resp.url)
                            that.setState({})
                        }} />
                )
            case "upload2":
                return (
                    <UploadFile2 title={props.title} url={deepGet(that.state, key)} disabled={props.disabled}
                                style={{
                                    height: "10rem",
                                    background: "rgb(250, 250, 250)",
                                    border: "1px solid rgba(34,36,38,.15)",
                                    borderRadius: ".28571429rem",
                                    ...props.style,
                                }}
                                imgType={props.imgType}
                                sourceid={props.sourceid}
                                newUrl={props.newUrl}
                                onChange={resp => {
                                    deepSet(that.state, key, resp.url)
                                    that.setState({})
                                }} />
                )
            case "trim":
                return (
                    <Input fluid min={0} {...props} type="text" value={deepGet(that.state, key)}
                        onChange={(e, { value }) => {
                            deepSet(that.state, key, (value || "").trim())
                            that.setState({})
                        }} />
                )
            case "parseFloat": 
                return (
                    <Input fluid min={0} {...props} value={deepGet(that.state, key)} disabled={props ? props.disabled : false}
                        onChange={(e, { value }) => {
                            let oldValue = deepGet(that.state, key)
                            // 输入中文小数点的话直接替换为英文的小数点
                            deepSet(that.state, key, `${value}`.replace("。", "."))
                            if (props && props._onChange) {
                                props._onChange(value, () => deepSet(that.state, key, oldValue === undefined ? "" : `${oldValue}`.replace("。", ".")))
                            }
                            that.setState({})
                        }} />
                ) 
            default:
                return (
                    <Input fluid min={0} {...props} value={deepGet(that.state, key) || (props && props.defaultValue)} disabled={props ? props.disabled : false}
                        onChange={(e, { value }) => {
                            let oldValue = deepGet(that.state, key)
                            deepSet(that.state, key, value)
                            if (props && props._onChange) {
                                props._onChange(value, () => deepSet(that.state, key, oldValue === undefined ? "" : oldValue))
                            }
                            that.setState({})
                        }} />
                )
        }
    },

    formInput2(that, key, props) {
        return (
            <Box>
                <div style={{ width: "1.25rem" }} />
                <Item flex>
                    {this.formInput(that, key, props)}
                </Item>
            </Box>
        )
    },

    formInput3(that, key, props, size = 1) {
        return (
            <div className="input-bg-white" style={{ width: (11.25 * size + 1.25 * (size - 1)) + "rem" }}>
                {this.formInput(that, key, { className: "input-bg-white", ...props })}
                {util.minHeight("1.25rem")}
            </div>
        )
    },

    formInput4(that, key, props) {
        return (
            <div>
                {this.formInput(that, key, props)}
                {util.minHeight("1.25rem")}
            </div>
        )
    },

    radioGroup(that, key, options, disabled = false) {
        let group = []
        for (let i = 0; i < options.length; i++) {
            let { value, text } = options[i]
            group.push(
                <Radio name={key} value={value} disabled={disabled}
                    checked={deepGet(that.state, key) === value}
                    onChange={() => {
                        deepSet(that.state, key, value)
                        that.setState({})
                    }} /> as never
            )
            group.push(
                <span style={{ marginLeft: ".5em" }}>
                    {text || value}
                </span> as never
            )
            if (i < options.length - 1) {
                group.push(<span style={{ padding: "0 .5em" }} /> as never)
            }
        }
        return group
    },

    radioGroup2(that, key, options, onChange?) {
        let group: any[] = []
        for (let i = 0; i < options.length; i++) {
            let { value, text } = options[i]
            group.push(
                <Box center className="line-height">
                    <Radio name={key} value={value}
                        checked={deepGet(that.state, key) === value}
                        onChange={() => {
                            deepSet(that.state, key, value)
                            that.setState({}, onChange)
                        }} />
                    <div style={{ marginLeft: "1em" }}>
                        {text}
                    </div>
                </Box>
            )
            if (i < options.length - 1) {
                group.push(<div style={{ minHeight: "1em" }} />)
            }
        }
        return group
    },

    checkboxGroup(that, key, options, multi?: boolean, expand?: boolean, mulitLimit?: number) {
        function onChange(value, checked) {
            if (multi) {
                let list: any[] = deepGet(that.state, key)
                if (checked) {
                    let optionValues = options.map(opt => opt.value)
                    if (optionValues.indexOf(value) < 0) {
                        for (let i = 0; i < list.length; i++) {
                            if (optionValues.indexOf(list[i]) < 0) list.splice(i, 1)
                        }
                    }
                    if (list.indexOf(value) < 0) list.push(value)
                } else {
                    while (list.indexOf(value) >= 0) {
                        list.splice(list.indexOf(value), 1)
                    }
                }
            } else {
                deepSet(that.state, key, value)
            }
            that.setState({})
        }

        if (multi) deepSet(that.state, key, deepGet(that.state, key) || [])
        let group: any[] = []

        for (let i = 0; i < options.length; i++) {
            let { value, text, children } = options[i]
            if (text === undefined) text = value
            let checked = false
            if (multi) {
                checked = (deepGet(that.state, key) as any[]).indexOf(value) >= 0
            } else {
                checked = deepGet(that.state, key) === value
            }
            if (children !== undefined) {
                group.push(
                    <Box vertical>
                        <Item>
                            <Checkbox name={key} value={value} label={text} checked={checked}
                                style={{ fontSize: "0.9375rem!important", marginRight: "1.25rem" }}
                                onChange={(e, { checked }) => onChange(value, checked)} />
                        </Item>
                        <Item>
                            <span style={{
                                fontSize: "0.625rem",
                                color: "#9b9b9b",
                            }}>{__.map(children, i => i.value).join('、')}</span>
                        </Item>
                    </Box>
                )
            } else {
                group.push(
                    <Checkbox name={key} value={value} label={text} checked={checked}
                        disabled={!!mulitLimit && !checked && multi && !!deepGet(that.state, key) && (deepGet(that.state, key) as any[]).length >= mulitLimit}
                        style={{ fontSize: ".85rem", marginRight: "1.25rem" }}
                        onChange={(e, { checked }) => onChange(value, checked)} />
                )
            }

        }

        let checked = false, otherValue = "";
        if (multi) {
            let currentValue: any[] = deepGet(that.state, key)
            for (let i = 0; i < currentValue.length; i++) {
                if (options.map(opt => opt.value).indexOf(currentValue[i]) < 0) {
                    checked = true
                    otherValue = currentValue[i]
                }
            }
        } else {
            let currentValue: string = deepGet(that.state, key)
            if (options.map(opt => opt.value).indexOf(currentValue) < 0) {
                checked = true
                otherValue = currentValue
            }
        }

        !expand ? (group.push(
            <div style={{ display: "inline-block" }}>
                <Box center>
                    <Checkbox name={key} value="其他" label="其他" checked={checked}
                        style={{ fontSize: ".85rem", marginRight: "1.25rem" }}
                        onChange={(e, { checked }) => onChange(otherValue, checked)} />
                    <Input value={otherValue} disabled={!checked} style={{ width: "19rem" }}
                        placeholder="选择”其他“时请输入"
                        onChange={(e, { value }) => onChange(value, true)} />
                </Box>
            </div>
        )) : null

        return <div style={{ lineHeight: "3rem" }}>{group}</div>
    },

    checkboxGroup2(that, key, options, multi?: boolean, expand?: boolean) {
        return (
            <Box style={{ marginRight: "-1.25rem" }}>
                <div style={{ width: "1.25rem" }} />
                <Item flex>
                    {this.checkboxGroup(that, key, options, multi, expand)}
                </Item>
            </Box>
        )
    },

    checkboxGroup3(that, key, options, multi?: boolean, expand?: boolean, mulitLimit?: number) {
        function onChange(value, checked) {
            if (multi) {
                let list: any[] = deepGet(that.state, key)
                if (checked) {
                    let optionValues = options.map(opt => opt.value)
                    if (optionValues.indexOf(value) < 0) {
                        for (let i = 0; i < list.length; i++) {
                            if (optionValues.indexOf(list[i]) < 0) list.splice(i, 1)
                        }
                    }
                    if (list.indexOf(value) < 0) list.push(value)
                } else {
                    while (list.indexOf(value) >= 0) {
                        list.splice(list.indexOf(value), 1)
                    }
                }
            } else {
                deepSet(that.state, key, value)
            }
            that.setState({})
        }

        if (multi) deepSet(that.state, key, deepGet(that.state, key) || [])
        let group: any[] = []

        for (let i = 0; i < options.length; i++) {
            let { value, text, children } = options[i]
            if (text === undefined) text = value
            let checked = false
            if (multi) {
                checked = (deepGet(that.state, key) as any[]).indexOf(value) >= 0
            } else {
                checked = deepGet(that.state, key) === value
            }
            if (children !== undefined) {
                group.push(
                    <Box vertical>
                        <Item style={{paddingTop: "0.18rem"}}>
                            <Checkbox name={key} value={value} label={text} checked={checked}
                                style={{ fontSize: "0.9375rem!import", marginRight: "1.25rem" }}
                                onChange={(e, { checked }) => onChange(value, checked)} />
                        </Item>
                        <div style={{ borderBottom: "1px solid #ecebeb", lineHeight: "1.25rem", height: "100%", paddingBottom: "0.9375rem" }}>
                            <span style={{
                                display: "inline-block",
                                margin: 0,
                                padding: 0,
                                lineHeight: "1.25rem",
                                fontSize: "0.8125rem",
                                color: "#9b9b9b",
                            }}>{__.map(children, i => i.value).join('、')}</span>
                        </div>
                    </Box>
                )
            } else {
                group.push(
                    <Checkbox name={key} value={value} label={text} checked={checked}
                        disabled={!!mulitLimit && !checked && multi && !!deepGet(that.state, key) && (deepGet(that.state, key) as any[]).length >= mulitLimit}
                        style={{ fontSize: ".85rem", marginRight: "1.25rem" }}
                        onChange={(e, { checked }) => onChange(value, checked)} />
                )
            }

        }

        let checked = false, otherValue = "";
        if (multi) {
            let currentValue: any[] = deepGet(that.state, key)
            for (let i = 0; i < currentValue.length; i++) {
                if (options.map(opt => opt.value).indexOf(currentValue[i]) < 0) {
                    checked = true
                    otherValue = currentValue[i]
                }
            }
        } else {
            let currentValue: string = deepGet(that.state, key)
            if (options.map(opt => opt.value).indexOf(currentValue) < 0) {
                checked = true
                otherValue = currentValue
            }
        }

        !expand ? (group.push(
            <div style={{ display: "inline-block" }}>
                <Box center>
                    <Checkbox name={key} value="其他" label="其他" checked={checked}
                        style={{ fontSize: ".85rem", marginRight: "1.25rem" }}
                        onChange={(e, { checked }) => onChange(otherValue, checked)} />
                    <Input value={otherValue} disabled={!checked} style={{ width: "19rem" }}
                        placeholder="选择”其他“时请输入"
                        onChange={(e, { value }) => onChange(value, true)} />
                </Box>
            </div>
        )) : null

        return <div style={{ lineHeight: "2rem" }}>{group}</div>
    },

    settingTitle(required: boolean | null, title, hint, props?) {
        return (
            <Box {...props} center style={{ height: ".85rem" }}>
                {required === null ? null : (
                    <div className="text-primary"
                        style={{ width: "1.25rem", fontSize: "1.4rem", fontWeight: 700, marginTop: ".6rem", minWidth: "1.25rem" }}>
                        {required ? "*" : ""}
                    </div>
                )}
                <div style={{ fontSize: ".85rem", fontWeight: 700 }}>
                    {title}
                </div>
                <div className="text-minor hint" style={{ fontSize: ".75rem", marginLeft: ".5em" }}>
                    {hint}
                </div>
            </Box>
        )
    },

    settingTitle2(required: boolean | null, title, hint, props?) {
        return (
            <Box {...props} center style={{ height: ".85rem" }}>
                {required === null ? null : (
                    <div
                        style={{ width: "1.25rem", fontSize: "1.4rem", fontWeight: 700, marginTop: ".6rem", minWidth: "1.25rem", color: "#f00" }}>
                        {required ? "*" : ""}
                    </div>
                )}
                <div style={{ fontSize: ".85rem", fontWeight: 700 }}>
                    {title}
                </div>
                <div className="" style={{ fontSize: ".75rem", marginLeft: ".5em", color:"red" }}>
                    {hint}
                </div>
            </Box>
        )
    },

    settingTitle3(required: boolean, title: string, height = "2.5rem") {
        return (
            <div>
                <Box center style={{ height: height, fontSize: ".85rem", marginRight: "1.25rem" }}>
                    <Item flex />
                    {required ? (
                        <div className="text-primary"
                            style={{ fontSize: "1.2em", marginRight: ".5em", height: "1em" }}>
                            *
                        </div>
                    ) : null}
                    {title}
                </Box>
                <div style={{ height: "1.25rem" }} />
            </div>
        )
    },

    settingTitle4(title: string, hint?) {
        return (
            <div style={{ fontSize: ".85rem" }}>
                <Box center>
                    <div style={{ fontSize: ".9rem" }}>
                        {title}
                    </div>
                    <div className="text-minor" style={{ fontSize: ".9rem" }}>
                        {hint}
                    </div>
                </Box>
                {util.minHeight("1.25rem")}
            </div>
        )
    },

    settingTitleIcon(title: string, hint?) {
        return (
            <div style={{ fontSize: ".85rem" }}>
                <Box center>
                    <div style={{ fontSize: ".85rem", fontWeight: 700 }}>
                        {title}
                    </div>
                    <div className="text-minor" style={{ fontSize: ".9rem" }}>
                        {hint}
                    </div>
                </Box>
            </div>
        )
    },

    settingTitle5(required: boolean | null, title, hint, props?) {
        return (
            <Box {...props} center style={{ height: ".85rem", paddingBottom: "1.2rem" }}>
                {required === null ? null : (
                    <div className="text-primary"
                        style={{ width: "1.25rem", height:"18px", fontSize: "1.4rem", fontWeight: 700, minWidth: "1.25rem", borderLeft: "0.3rem solid #290382" }}>
                    </div>
                )}
                <div style={{ fontSize: "0.9375rem", fontWeight: 600 }}>
                    {title}
                </div>
                <div className="" style={{ fontSize: ".75rem", marginLeft: ".5em", color:"red" }}>
                    {hint}
                </div>
            </Box>
        )
    },

    settingItemIcon(that, key: string, title, hint, props?) {
        return (
            <div>
                {this.settingTitleIcon(title, hint)}
                <Box style={{ margin: "1.25rem 0" }}>
                    <Item flex>
                        {this.formInput(that, key, props)}
                    </Item>
                </Box>
            </div>
        )
    },

    settingItem(that, key: string, required: boolean | null, title, hint, props?) {
        return props && props.type === "radio" ?
            <Box center style={{ margin: "1.25rem 0" }}>
                {this.settingTitle(required, title, hint)}
                <Item flex />
                {this.radioGroup(that, key, props.options, !!(props && props.disabled))}
            </Box>
            :
            <div>
                {this.settingTitle(required, title, hint)}
                <Box style={{ margin: "1.25rem 0" }}>
                    {required === null ? null : <div style={{ width: "1.25rem" }} />}
                    <Item flex>
                        {this.formInput(that, key, props)}
                    </Item>
                </Box>
            </div>
    },

    settingIconItem(that, key: string, required: boolean | null, title, hint, props?) {
        return <div>
            {this.settingTitle(required, title, hint)}
            <Box style={{ margin: "1.25rem 0" }}>
                {required === null ? null : <div style={{ width: "1.25rem" }} />}
                <Item flex>
                    {this.formInput(that, key, props)}
                </Item>
            </Box>
        </div>
    },

    settingItem2(that, key: string, required: boolean, title, hint, props?, hint2?) {
        return (
            <div>
                {this.settingTitle(required, title, hint)}
                <div style={{ minHeight: ".625rem" }} />
                {elements.formInput2(that, key, props)}
                {hint2 ? (
                    <div className="text-minor"
                        style={{ margin: ".625rem 0 0 1.25rem", fontSize: ".75rem" }}>
                        {hint2}
                    </div>
                ) : null}
            </div>
        )
    },

    settingItem3(that, key: string, required: boolean | null, title, hint, props?, hint2?) {
        return (
            <div>
                {this.settingTitle(required, title, hint)}
                <Box style={{ margin: ".625rem 0 1.25rem" }}>
                    {required === null ? null : <div style={{ width: "1.25rem" }} />}
                    <Item flex>
                        {this.formInput(that, key, props)}
                        {hint2 ? (
                            <div className="text-minor"
                                style={{ marginTop: ".625rem", fontSize: ".75rem" }}>
                                {hint2}
                            </div>
                        ) : null}
                    </Item>
                </Box>
            </div>
        )
    },

    settingItem4(that, key: string, required: boolean | null, title, hint, props?) {
        return props && props.type === "radio" ?
            <Box center style={{ margin: "1.25rem 0" }}>
                {this.settingTitle2(required, title, hint)}
                <Item flex />
                {this.radioGroup(that, key, props.options, !!(props && props.disabled))}
            </Box>
            :
            <div>
                {this.settingTitle2(required, title, hint)}
                <Box style={{ margin: "1.25rem 0" }}>
                    {/* {required === null ? null : <div style={{ width: "1.25rem" }} />} */}
                    <Item flex>
                        {this.formInput(that, key, props)}
                    </Item>
                </Box>
            </div>
    },

    settingItem5(that, key: string, required: boolean | null, props?) {
        return (
            <div>
                <Box style={{ fontSize: "0.9375rem", marginTop: ".625rem" }}>
                    {required === null ? null : <div style={{ width: "1.25rem" }} />}
                    <Item flex>
                        {this.formInput(that, key, props)}
                    </Item>
                </Box>
            </div>
        )
    },

    accountBinding(icon, title, content, action, onClick?) {
        return (
            <Box center>
                <Box centerJustified className="bg-color-primary"
                    style={{ width: "1.25rem", height: "1.25rem", borderRadius: 999 }}>
                    <Icon name={icon} style={{ color: "white", fontSize: ".85rem", marginTop: "-0.1em" }} />
                </Box>
                <div className="text-primary ml05" style={{ fontSize: ".8rem" }}>
                    {title}
                </div>
                <div title={content} className="text-minor text-center"
                    style={{ fontSize: ".8rem", width: "14rem", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {content}
                </div>
                <Button primary size="mini" style={{ width: "5rem", padding: ".75em 1em" }} onClick={onClick}>
                    {action}
                </Button>
            </Box>
        )
    },

    shareIcon(name: string) {
        return (
            <Box center centerJustified className="bg-color-primary clickable"
                style={{
                    height: "1.25rem", width: "1.25rem", borderRadius: 999,
                    fontSize: ".8rem", color: "white", lineHeight: "1.05rem",
                }}>
                <Icon name={name} />
            </Box>
        )
    },

    label(text, style?) {
        let defaultStyle = {
            borderRadius: 999,
            color: "#999",
            fontSize: ".7rem",
            border: "none",
            background: "#f5f5f5",
            display: "inline-block",
            ...style
        }
        return <Label basic style={defaultStyle}>{text}</Label>
    },

    /**
     * origin: 原创栏目
     */
    sourceEdit(source, setState?, sourceList?, origin?)  {
        try {
            if (source.sourceId && !source.sourceName) {
                let name = __.filter(sourceList, i => (i.sourceid as string).toLocaleLowerCase() === (source.sourceId as string).toLocaleLowerCase())[0].sourceName || ""
                source.sourceName = name
                if (setState) setState()
            } else if (!source.sourceId && source.sourceName) {
                let id = __.filter(sourceList, i => (i.sourceName as string).toLocaleLowerCase() === (source.sourceName as string).toLocaleLowerCase())[0].sourceid || ""
                let url = __.filter(sourceList, i => (i.sourceName as string).toLocaleLowerCase() === (source.sourceName as string).toLocaleLowerCase())[0].url || ""
                source.sourceId = id
                if (source.sourceType === "WEBSITE") {
                    source.url = url
                }
                if (setState) setState()
            }
        } catch (e) {
            console.error(e)
        }
        return (
            <Box>
                <Item style={{ width: "8.75rem" }}>
                    {/* 选择类型 */}
                    <Select fluid value={source.sourceType}
                        options={[
                            { value: "WEIXIN", text: "微信公众号" },
                            { value: "WEBSITE", text: "网站" },
                            { value: "OTHER", text: "其他" },
                            { value: "DEFAULT", text: "领研网" },
                        ]}
                        onChange={(_, { value }) => {
                            source.sourceType = value
                            source.url = null
                            source.sourceId = null
                            source.sourceName = null
                            if (value === "DEFAULT") {
                                source.id = source.sourceId = source.sourceName = ""
                                if(origin) origin.isOriginal = true
                            } else {
                                if(origin) origin.isOriginal = false
                            }
                            if (setState) setState()
                        }} />
                </Item>
                {util.minWidth(".625rem")}
                <Item flex>
                    {/* 选择sourceID或url */}
                    {
                        <Select 
                            fluid
                            search
                            value={(source.sourceType === "WEBSITE" ? source.url : source.sourceId) || ""}
                            disabled={source.sourceType === "DEFAULT"}
                            options={
                                __.map(__.filter(sourceList, i => i.type === source.sourceType), i => {
                                    return {
                                        key: source.sourceType === "WEBSITE" ? i.url : i.sourceid,
                                        value: source.sourceType === "WEBSITE" ? i.url : i.sourceid,
                                        text: source.sourceType === "WEBSITE" ? i.url : i.sourceid,
                                    }
                                })
                            }
                            onChange={(_, { value }) => {
                                if (source.sourceType === "WEIXIN" || source.sourceType === "OTHER") {
                                    source.sourceId = value
                                    const sourceObj = __.filter(sourceList, i => i.sourceid === value)[0]
                                    source.id = sourceObj.id
                                    if(source.sourceId === "huanqiukexue" || source.sourceId === "keyanquan") {
                                        if(origin) origin.isOriginal = true
                                    } else {
                                        if(origin) origin.isOriginal = false
                                    }
                                } else if (source.sourceType === "WEBSITE") {
                                    source.url = value
                                    const sourceObj = __.filter(sourceList, i => i.url === value)[0]
                                    source.sourceId = sourceObj.sourceid
                                    source.id = sourceObj.id
                                }
                                source.sourceName = __.filter(sourceList, i => (source.sourceType === "WEIXIN" || source.sourceType === "OTHER") ? i.sourceid === value : i.url === value)[0].sourceName
                                if (setState) setState()
                            }} />
                    }
                </Item>
                {util.minWidth(".625rem")}
                <Item flex>
                    {/* 选择名字 */}
                    {
                        <Select
                            fluid
                            search
                            value={source.sourceName || ""}
                            disabled={source.sourceType === "DEFAULT"}
                            options={
                                __.map(__.filter(sourceList, i => i.type === source.sourceType), i => {
                                    return {
                                        key: i.sourceName,
                                        value: i.sourceName,
                                        text: i.sourceName,
                                    }
                                })
                            }
                            onChange={(_, { value }) => {
                                source.sourceName = value
                                const curSource = __.filter(sourceList, i => i.sourceName === value)[0]
                                source.sourceId = curSource.sourceid
                                source.id = curSource.id
                                source.url = source.sourceType === "WEBSITE" ? __.filter(sourceList, i => i.sourceName === value)[0].url : null
                                if(source.sourceName === "环球科学" || source.sourceName === "科研圈") {
                                    if(origin) origin.isOriginal = true
                                } else {
                                    if(origin) origin.isOriginal = false
                                }
                                if (setState) setState()
                            }} />
                    }
                </Item>
                <Button size="tiny" basic title="刷新"
                    style={{ paddingLeft: "1rem", paddingRight: "1rem", width: "2.6rem", marginRight: "1.25rem", marginLeft: "1.25rem" }}
                    onClick={() => {
                        source.sourceName = null
                        source.sourceId = null
                        source.url = null
                        if (setState) setState()
                    }}>
                    <Icon name="refresh" />
                </Button>
            </Box>
        )
    },

    wechatQRCode: util.wechatQRCode,

    uploadAttachments(that) {
        that.state.form.attachments = that.state.form.attachments || []
        return [
            <Box center>
                <Item relative style={{ paddingLeft: "1.25rem" }}>
                    <Button primary size="mini" disabled={that.state.form.attachments.length >= 3}>
                        从本地上传
                    </Button>
                    {that.state.form.attachments.length >= 3 ? null : (
                        <Dropzone disablePreview className="fit-upload" config={{ postUrl: "/api/upload" }}
                            djsConfig={{
                                uploadMultiple: false,
                                parallelUploads: 1,
                                maxFilesize: 2,
                                acceptedFiles: ".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx",
                                params: {
                                    _csrf: (document.querySelector("meta[name='_csrf']") as any)["content"]
                                }
                            }}
                            eventHandlers={{
                                success(file) {
                                    if (that.state.form.attachments.length < 3) {
                                        let resp = JSON.parse(file.xhr.responseText)
                                        that.state.form.attachments.push({
                                            filename: resp.filename,
                                            url: resp.url,
                                        })
                                        that.setState({})
                                    }
                                },
                                error: util.uploadErrorHandler(),
                            }}>
                        </Dropzone>
                    )}
                </Item>
                {util.minWidth("1.25rem")}
                <div className="text-minor" style={{ fontSize: ".75rem" }}>
                    允许格式为 jpg/jpeg/png/pdf/doc/docx/xls/xlsx，单个文件不超过 2MB，最多 3 个附件
                </div>
            </Box>
            ,
            that.state.form.attachments.map((att, i) =>
                <Box center style={{ marginTop: "1rem", marginLeft: "1.25rem", fontSize: ".85rem" }}>
                    <a target="_blank" href={att.url + "?attname=" + att.filename}>
                        <Icon name={`file ${util.getIconName(att.filename)} outline`} style={{ fontSize: "1.2em", marginRight: ".5em" }} />
                        {att.filename}
                    </a>
                    {util.minWidth("1em")}
                    <Icon name="trash outline" className="text-primary clickable" style={{ lineHeight: "1.2em" }}
                        onClick={() => {
                            that.state.form.attachments.splice(i, 1)
                            that.setState({})
                        }} />
                </Box>
            )
        ]
    }
}

let scrollTopIconBackground = "#290382"
export const setScrollTopIconBackground = (color => scrollTopIconBackground = color)

if ((window as any)._template === "default") {
    let root: any = document.getElementById("root")
    if (root) {
        root.addEventListener("scroll", () => {
            let icon: any = document.getElementById("scroll-to-top")
            if (!icon) {
                icon = document.createElement("i")
                icon.id = "scroll-to-top"
                icon.className = "clickable"
                icon.style.background = scrollTopIconBackground
                icon.style.color = "white"
                icon.style.position = "fixed"
                icon.style.bottom = "75px"
                icon.style.right = "50px"
                icon.style.padding = "12px 10px"
                icon.style.borderRadius = "4px"
                icon.style.fontSize = "25px"
                icon.style.zIndex = "20"
                icon.style.lineHeight = "1"
                icon.innerHTML = `<i class="angle up icon" ></i>`
                icon.addEventListener("click", () => (document.getElementById("root") as any).scrollTop = 0)
                document.body.appendChild(icon)
            }
        })
    }
}
